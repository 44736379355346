import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class GenericTitleResolver  {

  constructor(private titleService: Title) { }

  resolve(route: ActivatedRouteSnapshot): string {
    const title = route.data.genericTitle;
    this.titleService.setTitle(title);
    return title;
  }
}
